<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2>VACANCIES</h2>
                    </div>
                </div>
            </div>
        </div>
        <Loader :isLoading="isLoading" />

        <div class="vacancies_area">
            <div class="container">
                <h1>
                    <span><ImageItem src="images/heart.png"/></span>Featured JOb Vacancies
                </h1>
                <router-link :to="{ name: 'create-job' }" v-if="authenticated && !isMaterialTester"
                    ><button class="btn btn-sm btn-primary mb-3">
                        <i class="fa fa-plus" /> Post Job
                    </button></router-link
                >
                <div v-if="!isLoading && vacancies.length <= 0">No job vacancies</div>
                <div class="row">
                    <div class="col-md-6" v-for="vacancy in vacancies" :key="vacancy.id">
                        <div class="vacancies_box">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="vacancies_box_image">
                                        <ImageItem :src="imgsrc + vacancy.image" />
                                    </div>
                                </div>
                                <div class="col-md-10">
                                    <div class="vacancies_box_heading">
                                        <h5>
                                            <router-link
                                                :to="{
                                                    name: 'applyforjob',
                                                    params: { id: vacancy.id },
                                                }"
                                                >{{ vacancy.job_title }}</router-link
                                            >
                                            - <span>{{ vacancy.company_name || "MEPC" }}</span>
                                        </h5>
                                        <div class="vacancies_box_rating" v-if="vacancy.star == 1">
                                            <i class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                        </div>
                                        <div class="vacancies_box_rating" v-if="vacancy.star == 2">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                        </div>
                                        <div class="vacancies_box_rating" v-if="vacancy.star == 3">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                        </div>
                                        <div class="vacancies_box_rating" v-if="vacancy.star == 4">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i style="color: #c1c1c1;" class="fa fa-star"></i>
                                        </div>
                                        <div class="vacancies_box_rating" v-if="vacancy.star == 5">
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                            <i class="fa fa-star"></i>
                                        </div>
                                    </div>
                                    <div class="vacancies_box_content">
                                        <span
                                            ><i class="fa fa-map-marker"></i>
                                            {{ vacancy.city }}</span
                                        >
                                        <span style="margin-left: 6px;"
                                            ><i class="fa fa-clock-o" aria-hidden="true"></i>
                                            {{ formatDate(vacancy.expiry_date) }}</span
                                        >
                                        <span style="float: right;"
                                            ><i class="fa fa-heart-o" aria-hidden="true"></i>
                                            {{ vacancy.job_type }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from "../_helper/http-constants"
import axios from "axios"
import scrollTop from "./utils/scrollTop"
import ImageItem from "./utils/ImageItem"
import request from "../apis/request"
import Loader from "./Loader.vue"
import { mapState, mapGetters } from "vuex"

export default {
    name: "vacancies",

    data() {
        return {
            vacancies: "",
            isLoading: false,

            //   imgsrc: 'http://103.212.120.205/~dev/public/admin_profile/',
            imgsrc: axios.defaults.uploadURL,
        }
    },

    components: {
        ImageItem,
        Loader,
    },
    mounted() {
        scrollTop()
        this.isLoading = true
        request.get("/job").then((res) => {
            this.isLoading = false
            this.vacancies = res.data
        })
    },
    methods: {
        formatDate(date) {
            date = new Date(date)
            return date
                .toISOString()
                .slice(0, 10)
                .split("-")
                .reverse()
                .join("-")
        },
    },
    computed: {
        ...mapState({
            authenticated: "auth/authenticated",
        }),
        ...mapGetters("auth", {
            isMaterialTester: "isMaterialTester",
        }),
    },
}
</script>
